import React from 'react'

const Footer = () => {
  return (
    <>
        <section>
            <h1 className="text-center m-8">Copyright &copy; 2023 Benny Goh</h1>
        </section>
    </>
  )
}

export default Footer