import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProjectPreview.module.css';
import YelpCampImage from './../../assets/images/yelpcamppreview.png';
//import WebProjects from './../../assets/images/2022webprojects.png';
//import BennyShoes from './../../assets/images/bennyshoes.png';
//import GPACalculator from './../../assets/images/gpacalculator.png';
import Airbnb from './../../assets/images/airbnb-thumbnail.png';
import Bookify from './../../assets/images/bookify_banner.png';
import Foodsnap from './../../assets/images/foodsnap_banner.png';
import Javascript from './../../assets/images/javascript.png';
import Typescript from './../../assets/images/typescript.png';
import Nodejs from './../../assets/images/nodejs.png';
import Nextjs from './../../assets/images/nextjs.svg';
import NextAuth from './../../assets/images/nextauth.png';
import Cloudinary from './../../assets/images/cloudinary.png';
import Express from './../../assets/images/expressjs.png';
import Mongodb from './../../assets/images/mongodb.png';
import Bootstrap from './../../assets/images/bootstrap.png';
import Flutter from './../../assets/images/flutter.png';
import Dart from './../../assets/images/dart.png';
import Prisma from './../../assets/images/prisma.png';
import ReactLogo from './../../assets/images/reactjs.png';
import ReactRouter from './../../assets/images/react-router.svg';
import Tailwind from './../../assets/images/tailwind.png';
import Sqlite from './../../assets/images/sqlite.png';
import Aspnet from './../../assets/images/aspnet.png';
import Dotnet from './../../assets/images/dotnetcore.png';

const ProjectPreview = () => {
  return (
    <>
        <h1 className="text-3xl font-extrabold mb-8 orangetext" id="projects">
                Featured Projects
            </h1>

            <section
                className="flex flex-col md:flex-row items-center justify-between mb-16"
            >
                <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
                    <a href="https://github.com/bennygdev/airbnb-clone" target="_blank" rel="noopener noreferrer">
                        <img
                            src={Airbnb}
                            alt="Airbnb Clone"
                            className={styles.project__image}
                            draggable="false"
                        />
                    </a>
                </div>
                <div className="md:w-1/2">
                    <h1 className={`${styles.project__title} bluetext`}>Airbnb Clone</h1>
                    <div className="flex mb-4">
                        <img
                            src={Typescript}
                            className={`mr-3`}
                            title="Typescript"
                            alt="Typescript"
                            width="40"
                        />
                        <img
                            src={Nextjs}
                            className={`mr-3`}
                            title="NextJS"
                            alt="NextJS"
                            width="40"
                        />
                        <img
                            src={Tailwind}
                            className={`mr-3`}
                            title="Tailwind"
                            alt="Tailwind"
                            width="40"
                        />
                        <img
                            src={Prisma}
                            className={`mr-3`}
                            title="Prisma"
                            alt="Prisma"
                            width="35"
                        />
                        <img
                            src={Mongodb}
                            className="mr-3"
                            title="MongoDB"
                            alt="MongoDB"
                            width="40"
                        />
                        <img
                            src={NextAuth}
                            className="mr-3"
                            title="NextAuth"
                            alt="NextAuth"
                            width="40"
                        />
                        <img
                            src={Cloudinary}
                            className="mr-3"
                            title="Cloudinary"
                            alt="Cloudinary"
                            width="60"
                        />
                    </div>
                    <h4 className={styles.project__description}>
                        Full-Stack web application that replicates Airbnb's website made for creating properties and booking reservations
                    </h4>
                    <div className="flex mb-4">
                        <a href="https://benny-airbnb.vercel.app/" target="_blank" rel="noopener noreferrer">
                            <button className={`${styles.button__demo} mr-4`}>Demo</button>
                        </a>
                    </div>
                </div>
            </section>

            <section
                className="flex flex-col md:flex-row items-center justify-between py-16 px-6 mb-12"
            >
                <div className="md:w-1/2 flex justify-end">
                    <div className="text-right">
                        <h1 className={`${styles.project__title} bluetext`}>
                            YelpCamp
                        </h1>
                        <div className="flex mb-4 justify-end">
                            <img
                                src={Javascript}
                                className={`${styles.project__icons} mr-3`}
                                title="Javascript"
                                alt="Javascript"
                                width="40"
                            />
                            <img
                                src={Nodejs}
                                className={`${styles.project__icons} mr-3`}
                                title="NodeJS"
                                alt="NodeJS"
                            />
                            <img
                                src={Express}
                                className={`${styles.project__icons} mr-3`}
                                title="ExpressJS"
                                alt="ExpressJS"
                            />
                            <img
                                src={Mongodb}
                                className={`${styles.project__icons} mr-3`}
                                title="MongoDB"
                                alt="MongoDB"
                            />
                            <img
                                src={Bootstrap}
                                className="mr-3"
                                title="Bootstrap"
                                alt="Bootstrap"
                                width="45"
                            />
                            <img
                                src={Cloudinary}
                                className="mr-3"
                                title="Cloudinary"
                                alt="Cloudinary"
                                width="60"
                            />
                        </div>
                        <h4 className={styles.project__description}>
                            YelpCamp is a full-stack web project which allows users
                            to create and review campgrounds, providing a platform
                            for sharing and discovering outdoor experiences
                        </h4>
                        <div className="flex mb-4 justify-end">
                            <a href="https://benny-yelpcamp.onrender.com/" target="_blank" rel="noopener noreferrer">
                                <button className={`${styles.button__reverseddemo} mr-4`}>
                                    Demo
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end">
                    <a href="https://github.com/bennygdev/bookify" target="_blank" rel="noopener noreferrer">
                        <img
                            src={YelpCampImage}
                            alt="YelpCamp"
                            className={`${styles.project__image} float-right`}
                            draggable="false"
                        />
                    </a>
                </div>
            </section>

            <section
                className="flex flex-col md:flex-row items-center justify-between mb-16"
            >
                <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
                    <a href="https://github.com/bennygdev/foodsnap-v1/" target="_blank" rel="noopener noreferrer">
                        <img
                            src={Bookify}
                            alt="Bookify"
                            className={styles.project__image}
                            draggable="false"
                        />
                    </a>
                </div>
                <div className="md:w-1/2">
                    <h1 className={`${styles.project__title} bluetext`}>Bookify</h1>
                    <div className="flex mb-4">
                        <img
                            src={Javascript}
                            className={`${styles.project__icons} mr-3`}
                            title="Javascript"
                            alt="Javascript"
                        />
                        <img
                            src={ReactLogo}
                            className={`${styles.project__icons} mr-3`}
                            title="ReactJS"
                            alt="ReactJS"
                        />
                        <img
                            src={ReactRouter}
                            className={`${styles.project__icons} mr-3`}
                            title="React Router"
                            alt="React Router"
                        />
                        <img
                            src={Tailwind}
                            className={`${styles.project__icons} mr-3`}
                            title="Tailwind"
                            alt="Tailwind"
                        />
                        <img
                            src={Aspnet}
                            className={`${styles.project__icons} mr-3`}
                            title="ASP.NET"
                            alt="ASP.NET"
                        />
                        <img
                            src={Dotnet}
                            className={`${styles.project__icons} mr-3`}
                            title=".NET"
                            alt=".NET"
                        />
                    </div>
                    <h4 className={styles.project__description}>
                        Full-Stack React application made for clients to book facilities, made with React-Router and Core Web API
                    </h4>
                </div>
            </section>

            <section
                className="flex flex-col md:flex-row items-center justify-between py-16 px-6 mb-6"
            >
                <div className="md:w-1/2 flex justify-end">
                    <div className="text-right">
                        <h1 className={`${styles.project__title} bluetext`}>
                            Foodsnap
                        </h1>
                        <div className="flex mb-4 justify-end">
                            <img
                                src={Flutter}
                                className={`${styles.project__icons} mr-3`}
                                title="Flutter"
                                alt="Flutter"
                                width="35"
                            />
                            <img
                                src={Dart}
                                className="mr-3"
                                title="Dart"
                                alt="Dart"
                                width="45"
                            />  
                            <img
                                src={Sqlite}
                                className="mr-3"
                                title="SQLite"
                                alt="SQLite"
                                width="45"
                            /> 
                        </div>
                        <h4 className={styles.project__description}>
                            A mobile app to track your calories, BMI and your water intake to promote a healthy lifestyle
                        </h4>
                        <div className="flex mb-4 justify-end">
                            
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end">
                    <a href="https://github.com/bennygdev/Benshoes-Website" target="_blank" rel="noopener noreferrer">
                        <img
                            src={Foodsnap}
                            alt="Foodsnap"
                            className={`${styles.project__image} float-right`}
                            draggable="false"
                        />
                    </a>
                </div>
            </section>

            <div className="flex mb-8 justify-center">
                <Link to="/upcomingprojects/">
                    <button className={`${styles.button__moreProjects} mr-4`}>Upcoming Projects</button>
                </Link>
            </div>
    </>
  )
}

export default ProjectPreview