import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Project from './Project';

function Projects() {
  return (
    <Routes>
      <Route 
        path="/yelpcamp" 
        element={<Project />}
      ></Route>
      <Route 
        path="/bookify" 
        element={<h1>Bookify!</h1>}
      ></Route>
      <Route 
        path="/foodsnap" 
        element={<h1>Foodsnap!</h1>}
      ></Route>
      <Route 
        path="/benshoes" 
        element={<h1>Benshoes!</h1>}
      ></Route>
    </Routes>
  );
}

export default Projects;