import Layout from "./pages/Layout/Layout";
import Home from "./pages/Home/Home";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import NotFound from "./pages/NotFound/NotFound";
import UpcomingProjects from "./components/UpcomingProjects/UpcomingProjects";
import Projects from "./components/Projects/Projects";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="*" element={<NotFound />}></Route>
      <Route path="/upcomingprojects/" element={<UpcomingProjects />}></Route>
      <Route path="/projects/*" element={<Projects />}></Route>
    </Route>
  )
)

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}


export default App;
