import React from 'react';

const AboutDescription = () => {
  return (
    <>
        <section className="flex flex-col mt-64 mb-16">
            <h1 className="text-3xl font-extrabold mb-8 orangetext">About</h1>
            <p>
            Hey there! I’m Benny, a Software Developer from Singapore. I have a passion in creating products that not only look  visually appealing but also provide a seamless and enjoyable user experience.

            I'm always open to try and learn new things, and constantly seeking new ways to improve my skills and knowledge. I'm excited where this journey takes me!
            </p>
        </section>
    </>
  )
}

export default AboutDescription