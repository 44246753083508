import React from 'react';
import styles from './Skills.module.css'
import Python from './../../assets/images/python.png';
import Javascript from './../../assets/images/javascript.png';
import Typescript from './../../assets/images/typescript.png';
import Html from './../../assets/images/html.png'; 
import Css from './../../assets/images/css.png';
import Sql from './../../assets/images/sql.png';
import Php from './../../assets/images/php.png';
import Dart from './../../assets/images/dart.png';
import Csharp from './../../assets/images/csharp.png';
import Flutter from './../../assets/images/flutter.png'; 
import Nodejs from './../../assets/images/nodejs.png';
import Express from './../../assets/images/expressjs.png';
import ReactJS from './../../assets/images/reactjs.png';
import NextJS from './../../assets/images/nextjs.svg';
import Aspnet from './../../assets/images/aspnet.png';
import Dotnet from './../../assets/images/dotnetcore.png';
import Wordpress from './../../assets/images/wordpress.png';
import Bootstrap from './../../assets/images/bootstrap.png';
import Tailwind from './../../assets/images/tailwind.png';
import VisualStudioCode from './../../assets/images/visualstudiocode.png';
import AndroidStudio from './../../assets/images/androidstudio.png';
import VisualStudio from './../../assets/images/visualstudio.png';
import Sqlserver from './../../assets/images/ssms.png';
import AdobePhotoshop from './../../assets/images/photoshop.png';
import AdobeIllustrator from './../../assets/images/illustrator.png';
import AdobeXD from './../../assets/images/xd.png';
import Inkscape from './../../assets/images/inkscape.png';
import Git from './../../assets/images/git.png';
import Npm from './../../assets/images/npm.png';
import Mysql from './../../assets/images/mysql.png';
import Mongodb from './../../assets/images/mongodb.png';
import Prisma from './../../assets/images/prisma.png';
import Cloudinary from './../../assets/images/cloudinary.png';
import NextAuth from './../../assets/images/nextauth.png';


const Skills = () => {
  return (
    <>
    <section className="flex flex-col mb-16">
                <h1 className="text-3xl font-extrabold mb-8 orangetext" id="skills">
                    Skills
                </h1>
                <h5 className="mb-2">Programming Languages:</h5>
                <div className="flex flex-wrap">
                    <img
                        src={Python}
                        className="mr-3"
                        title="Python"
                        alt="Python"
                        width="40"
                        height="35"
                    />
                    <img
                        src={Javascript}
                        className="mr-3"
                        title="Javascript"
                        alt="Javascript"
                        width="45"
                    />
                    <img
                        src={Typescript}
                        className="mr-3"
                        title="Typescript"
                        alt="Typescript"
                        width="45"
                    />
                    <img
                        src={Html}
                        className="mr-3"
                        title="HTML"
                        alt="HTML"
                        width="40"
                    />
                    <img
                        src={Css}
                        className="mr-3"
                        title="CSS"
                        alt="CSS"
                        width="40"
                    />
                    <img
                        src={Sql}
                        className={`${styles.project__icons} mr-3`}
                        title="SQL"
                        alt="SQL"
                    />
                    <img
                        src={Php}
                        className="mr-3"
                        title="PHP"
                        alt="PHP"
                        width="45"
                    />
                    <img
                        src={Dart}
                        className="mr-3"
                        title="Dart"
                        alt="Dart"
                        width="45"
                    />
                    <img
                        src={Csharp}
                        className="mr-3"
                        title="Csharp"
                        alt="Csharp"
                        width="40"
                    />
                </div>

                <h5 className="mb-2 mt-8">Frameworks & Platforms:</h5>
                <div className="flex flex-wrap">
                    <img
                        src={ReactJS}
                        className={`mr-3`}
                        title="ReactJS"
                        alt="ReactJS"
                        width="50"
                    />
                    <img
                        src={NextJS}
                        className="mr-3"
                        title="NextJS"
                        alt="NextJS"
                        width="40"
                    />
                    <img
                        src={NextAuth}
                        className="mr-3"
                        title="NextAuth"
                        alt="NextAuth"
                        width="40"
                    />
                    <img
                        src={Flutter}
                        className={`${styles.project__icons} mr-3`}
                        title="Flutter"
                        alt="Flutter"
                    />
                    <img
                        src={Nodejs}
                        className={`${styles.project__icons} mr-3`}
                        title="NodeJS"
                        alt="NodeJS"
                    />
                    <img
                        src={Express}
                        className="mr-3"
                        title="ExpressJS"
                        alt="ExpressJS"
                        width="40"
                    />
                    <img
                        src={Aspnet}
                        className="mr-3"
                        title="ASP.NET"
                        alt="ASP.NET"
                        width="50"
                    />
                    <img
                        src={Dotnet}
                        className="mr-3"
                        title=".NET Core"
                        alt=".NET Core"
                        width="45"
                    />
                    <img
                        src={Wordpress}
                        className="mr-3"
                        title="Wordpress"
                        alt="Wordpress"
                        width="45"
                    />
                    <img
                        src={Bootstrap}
                        className="mr-3"
                        title="Bootstrap"
                        alt="Bootstrap"
                        width="50"
                    />
                    <img
                        src={Tailwind}
                        className="mr-3"
                        title="Tailwind"
                        alt="Tailwind"
                        width="40"
                    />
                </div>

                <h5 className="mb-2 mt-8">Software & Tools:</h5>
                <div className="flex flex-wrap">
                    <img
                        src={VisualStudioCode}
                        className="mr-3"
                        title="Visual Studio Code"
                        alt="Visual Studio Code"
                        width="45"
                    />
                    <img
                        src={AndroidStudio}
                        className="mr-3"
                        title="Android Studio"
                        alt="Android Studio"
                        width="45"
                    />
                    <img
                        src={VisualStudio}
                        className="mr-3"
                        title="Visual Studio 2022"
                        alt="Visual Studio 2022"
                        width="45"
                    />
                    <img
                        src={Sqlserver}
                        className="mr-3"
                        title="SQL Server Management Studio"
                        alt="SQL Server Management Studio"
                        width="45"
                    />
                    <img
                        src={AdobePhotoshop}
                        className="mr-3"
                        title="Adobe Photoshop"
                        alt="Adobe Photoshop"
                        width="45"
                    />
                    <img
                        src={AdobeIllustrator}
                        className="mr-3"
                        title="Adobe Illustrator"
                        alt="Adobe Illustrator"
                        width="45"
                    />
                    <img
                        src={AdobeXD}
                        className="mr-3"
                        title="Adobe XD"
                        alt="Adobe XD"
                        width="45"
                    />
                    <img
                        src={Inkscape}
                        className="mr-3"
                        title="Inkscape"
                        alt="Inkscape"
                        width="45"
                    />
                    <img
                        src={Git}
                        className="mr-3"
                        title="Git"
                        alt="Git"
                        width="45"
                    />
                    <img
                        src={Npm}
                        className="mr-3"
                        title="NPM"
                        alt="NPM"
                        width="40"
                    />
                    <img
                        src={Mysql}
                        className="mr-3"
                        title="MySQL"
                        alt="MySQL"
                        width="45"
                    />
                    <img
                        src={Mongodb}
                        className="mr-3"
                        title="MongoDB"
                        alt="MongoDB"
                        width="40"
                    />
                    <img
                        src={Prisma}
                        className="mr-3"
                        title="Prisma"
                        alt="Prisma"
                        width="35"
                    />
                    <img
                        src={Cloudinary}
                        className="mr-3"
                        title="Cloudinary"
                        alt="Cloudinary"
                        width="65"
                    />
                </div>
                <h5 className="mb-2 mt-8">Currently learning:</h5>
                <div className="flex flex-row">
                    <img
                        src={Typescript}
                        className="mr-3"
                        title="TypeScript"
                        alt="TypeScript"
                        width="40"
                    />
                    <img
                        src={NextJS}
                        className="mr-3"
                        title="NextJS"
                        alt="NextJS"
                        width="40"
                    />
                    <img
                        src={Prisma}
                        className="mr-3"
                        title="Prisma"
                        alt="Prisma"
                        width="35"
                    />
                </div>
            </section>
    </>
  )
}

export default Skills