import { Route, Link, Routes } from "react-router-dom";
import { useEffect } from "react";
import styles from './UpcomingProjects.module.css';
import Weatherly from './../../assets/images/weatherlypreview.png';
import NoteIt from './../../assets/images/noteitapp.png';
import Javascript from './../../assets/images/javascript.png';
import React from './../../assets/images/reactjs.png';
import ReactRouter from './../../assets/images/react-router.svg';
import Redux from './../../assets/images/redux.svg';
import Tailwind from './../../assets/images/tailwind.png';
import Flutter from './../../assets/images/flutter.png';
import Dart from './../../assets/images/dart.png';
import Sqlite from './../../assets/images/sqlite.png';

function UpcomingProject() {
  useEffect(() => {
    window.scrollTo({top : 0});
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<UpcomingProjects />}
      ></Route>
    </Routes>
  );
}

const UpcomingProjects = () => {
  return (
    <>
      <section className="mb-16 mt-8">
        <Link to="/" className="text-xl">
          <i class="fa-solid fa-chevron-left"></i> Back to home
        </Link>
      </section>

      <h1 className="text-3xl font-extrabold mb-8 bluetext" id="projects">
                Upcoming Projects
            </h1>

            <section
                className="flex flex-col md:flex-row items-center justify-between mb-16"
            >
                <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
                    <a href="bennygoh.me/upcomingprojects/" rel="noopener noreferrer">
                        <img
                            src={Weatherly}
                            alt="Weatherly"
                            className={styles.project__image}
                            draggable="false"
                        />
                    </a>
                </div>
                <div className="md:w-1/2">
                    <h1 className={`${styles.project__title} bluetext`}>Weatherly</h1>
                    <div className="flex mb-4">
                        <img
                            src={Javascript}
                            className={`${styles.project__icons} mr-3`}
                            title="Javascript"
                            alt="Javascript"
                            width="45"
                        />
                        <img
                          src={React}
                          className={`mr-3`}
                          title="React"
                          alt="React"
                          width="40"
                        />
                        <img
                            src={ReactRouter}
                            className={`${styles.project__icons} mr-3`}
                            title="NodeJS"
                            alt="NodeJS"
                        />
                        <img
                            src={Redux}
                            className={`${styles.project__icons} mr-3`}
                            title="Redux"
                            alt="Redux"
                        />
                        <img
                            src={Tailwind}
                            className={`${styles.project__icons} mr-3`}
                            title="Tailwind"
                            alt="Tailwind"
                            width="45"
                        />
                    </div>
                    <h4 className={styles.project__description}>
                        Weatherly is a upcoming react project developed primarily using React, where it allows users to search for a city and get the current weather conditions for that location
                    </h4>
                </div>
            </section>

            <section
                className="flex flex-col md:flex-row items-center justify-between py-16 px-6 mb-12"
            >
                <div className="md:w-1/2 flex justify-end">
                    <div className="text-right">
                        <h1 className={`${styles.project__title} bluetext`}>
                            NoteIt
                        </h1>
                        <div className="flex mb-4 justify-end">
                            <img
                                src={Flutter}
                                className={`${styles.project__icons} mr-3`}
                                title="Flutter"
                                alt="Flutter"
                                width="35"
                            />
                            <img
                                src={Dart}
                                className="mr-3"
                                title="Dart"
                                alt="Dart"
                                width="45"
                            />
                            <img
                                src={Sqlite}
                                className={`mr-3`}
                                title="Sqlite"
                                alt="Sqlite"
                                width="45"
                            />
                        </div>
                        <h4 className={styles.project__description}>
                          NoteIt is a note-taking mobile app written in Flutter that allows users to take and store notes with customisable options. Users can freely change their note theme with cover images
                        </h4>
                    </div>
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end">
                    <a href="bennygoh.me/upcomingprojects/" rel="noopener noreferrer">
                        <img
                            src={NoteIt}
                            alt="2022 Web Projects"
                            className={`${styles.project__image} float-right`}
                            draggable="false"
                        />
                    </a>
                </div>
            </section>

            <div className="flex mb-8 justify-center">
                <Link to="/">
                    <button className={`${styles.button__home} mr-4`}>Back To Home</button>
                </Link>
            </div>
    </>
  )
}

export default UpcomingProject;

