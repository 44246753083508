import React from "react";
import styles from './Navbar.module.css';
import bennyLogo from './../../assets/images/BGLogo2.png'

const Navbar = () => {
    return (
        <>
            <header className={styles.header}>
                <nav className={styles.nav}>
                    <a href="/">
                        <img src={bennyLogo} alt="" />
                    </a>
                    <ul className={`${styles.nav__links} flex flex-col sm:flex-row divide-y sm:divide-y-0`}>
                        <li>
                            <a href="/" className={styles.nav__link}>
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/#projects" className={styles.nav__link}>
                                Projects
                            </a>
                        </li>
                        <li>
                            <a href="/#skills" className={styles.nav__link}>
                                Skills
                            </a>
                        </li>
                        <li>
                            <a href="/#contact" className={styles.nav__link}>
                                Contact
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    );
};

export default Navbar;
