import React from 'react'
// import Navbar from '../../components/Navigation/Navbar'
import AboutHero from '../../components/About/AboutHero'
import AboutDescription from '../../components/About/AboutDescription'
import ProjectPreview from '../../components/ProjectPreview/ProjectPreview'
import Skills from '../../components/Skills/Skills'
import Footer from '../../components/Footer/Footer'
import GetInTouch from '../../components/About/GetInTouch'

const Home = () => {
  return (
    <>
        <AboutHero />
        <AboutDescription />
        <ProjectPreview />
        <Skills />
        <GetInTouch />
        <Footer />
    </>
  )
}

export default Home