import { Link } from "react-router-dom";
import { React, useEffect } from "react";
import styles from "./../UpcomingProjects/UpcomingProjects.module.css"

function Project({name, contents}) {
  useEffect(() => {
    window.scrollTo({top : 0});
  }, []);

  return (
    <>
      <section className="mb-16 mt-8">
        <Link to="/" className="text-xl">
          <i class="fa-solid fa-chevron-left"></i> Back to home
        </Link>
      </section>
      <section>

      </section>
      <div className="flex mb-8 justify-center">
        <Link to="/">
          <button className={`${styles.button__home} mr-4`}>Back To Home</button>
        </Link>
      </div>
    </>
  )
}

export default Project