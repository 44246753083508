import React from 'react'
import styles from './Layout.module.css'
import { Outlet } from "react-router-dom";
import Navbar from '../../components/Navigation/Navbar';

function Layout(props) {
  return (
    <div className={styles.layout}>
        <Navbar />
        {props.children}

        <main>
          <Outlet />
        </main>
    </div>
  )
}

export default Layout