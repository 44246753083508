import React from 'react'
import {Link} from "react-router-dom";

const NotFound = () => {
  return (
    <>
        <div class="m-8">
            <h1 class="text-4xl mb-2">Page not found!</h1>
            <p>Go back to <Link to="/" class="orangetext">Home</Link></p>
        </div>
    </>
  )
}

export default NotFound