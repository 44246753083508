import React from 'react';
import styles from './AboutHero.module.css';
//import bennyLogo from './../../assets/images/BGLogo.png';
import benny from './../../assets/images/benny2.png';
import linkedin from './../../assets/images/linkedin.png';
import github from './../../assets/images/github-mark.png';
import BennyResume from '../../assets/resume/BennyGohResume.pdf';

const AboutHero = () => {
  return (
    <>
        <section
                className="flex flex-col md:flex-row items-center justify-between py-16 mt-32 mb-12"
            >
                <div className="md:w-1/2">
                    <h1 className="text-5xl font-extrabold mb-8 bluetext">
                        Hey! I'm <span className="orangetext">Benny</span> 👋
                    </h1>
                    <h2 className="text-2xl font-medium mb-10">
                        An aspiring software developer based in Singapore.
                    </h2>
                    <div className="flex mb-8">
                        <a href={BennyResume} target="_blank" rel="noopener noreferrer">
                            <button className={`${styles.button__resume} mr-6`}>Resume</button>
                        </a>
                        <a href="mailto:bennygoh14@gmail.com">
                            <button className={styles.button__contact}>Contact</button>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <h1 className="text-xl font-black mr-6">Socials</h1>
                        <h1 className="text-2xl mr-6">|</h1>

                        <a href="https://www.linkedin.com/in/benny-goh-sg/" target="_blank" rel="noopener noreferrer" className={styles.social__icon}>
                            <img src={linkedin} alt="Linkedin" width="35" />
                        </a>

                        <a href="https://github.com/bennygdev" target="_blank" rel="noopener noreferrer" className={`${styles.social__icon} mx-3`}>
                            <img src={github} alt="Github" width="35" />
                        </a>
                    </div>                    
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end">
                    <img
                        src={benny}
                        alt="BGLogo"
                        className="rounded-full w-96"
                        draggable="false"
                    />
                </div>
            </section>
    </>
  )
}

export default AboutHero