import React from 'react'
import styles from './GetInTouch.module.css';
import Skyline from './../../assets/images/SingaporeSkyline.png';

const GetInTouch = () => {
  return (
    <>
        <h1 className="text-3xl font-extrabold mt-10 orangetext" id="contact">
                Get In Touch
        </h1>

            <section
                className="flex flex-col md:flex-row items-center justify-between mb-12"
            >
                <div className="md:w-1/2 justify-end">
                    <p className="mb-4">
                        Interested in working with me? Feel free to reach out
                        through email! I will try to respond as soon as I can.
                    </p>
                    <a href="mailto:bennygoh14@gmail.com">
                        <button className={styles.button__redcontact}>Contact</button>
                    </a>
                </div>
                <div className="md:w-1/2 flex justify-center md:justify-end">
                    <img
                        src={Skyline}
                        alt="singapore skyline"
                        className="float-right"
                        draggable="false"
                    />
                </div>
            </section>
    </>
  )
}

export default GetInTouch